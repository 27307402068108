import { systemInfo } from '../../api';
export var GET_SYSTEM_INFO_SUCCESS = 'GET_SYSTEM_INFO_SUCCESS';
export var GET_SYSTEM_INFO_FAILURE = 'GET_SYSTEM_INFO_FAILURE';
export var getSystemInfo = function () { return function (dispatch) {
    return systemInfo
        .get()
        .then(function (systemInfo) {
        dispatch({
            type: GET_SYSTEM_INFO_SUCCESS,
            data: systemInfo,
        });
    })
        .catch(function (err) {
        return dispatch({
            type: GET_SYSTEM_INFO_FAILURE,
            data: err,
        });
    });
}; };
