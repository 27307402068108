var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Form from '../components/Form';
import InputText from '../components/InputText';
import t from '../lib/translate';
import { login } from '../redux/actions/authentication';
import SystemInformationBar from './SystemInformationBar';
import Logo from './../images/Cargostream.svg';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
var envLink = function () {
    switch (process.env.REACT_APP_ENV) {
        case 'dev':
            return 'dev-';
        case 'stg':
            return 'stg-';
        default:
            return '';
    }
};
var LoginPage = function () {
    var dispatch = useAppDispatch();
    var _a = useState(''), username = _a[0], setUsername = _a[1];
    var _b = useState(''), password = _b[0], setPassword = _b[1];
    var isLoading = useAppSelector(function (state) { return state.isLoading; });
    var error = useAppSelector(function (state) { return state.error.authentication; });
    var handleSetPassword = function (e) {
        setPassword(e.target.value);
    };
    var handleSetUsername = function (e) {
        setUsername(e.target.value);
    };
    var handleSubmit = function () {
        if (username && password) {
            dispatch(login({ username: username, password: password }));
        }
    };
    return (_jsxs("div", __assign({ className: 'login-page' }, { children: [_jsx("header", { children: _jsx("div", __assign({ className: 'header-wrapper' }, { children: _jsx("div", __assign({ className: 'brand' }, { children: _jsx(Logo, {}) })) })) }), _jsx(SystemInformationBar, {}), _jsxs(Form, __assign({ buttonLabel: 'button.label.login', isLoading: isLoading, onSubmit: handleSubmit, error: error, hideCancel: true, enableSubmit: !!(username && password) }, { children: [_jsx(InputText, { name: 'username', value: username, label: 'login.username', handleChange: handleSetUsername, autoFocus: true, autoComplete: 'username' }), _jsx(InputText, { name: 'password', type: 'password', autoComplete: 'password', value: password, label: 'login.password', handleChange: handleSetPassword })] })), _jsx("a", __assign({ className: 'reset-password-link link', href: "https://".concat(envLink(), "app.di.no/auth/static/password/reset") }, { children: t('login.password.expired.link') }))] })));
};
export default LoginPage;
