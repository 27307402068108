var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import t from '../lib/translate';
var InputText = function (_a) {
    var name = _a.name, value = _a.value, defaultValue = _a.defaultValue, label = _a.label, handleChange = _a.handleChange, maxLength = _a.maxLength, autoFocus = _a.autoFocus, autoComplete = _a.autoComplete, _b = _a.placeholder, placeholder = _b === void 0 ? label : _b, _c = _a.type, type = _c === void 0 ? 'text' : _c, min = _a.min, max = _a.max, step = _a.step, pattern = _a.pattern;
    return (_jsxs("div", __assign({ className: 'input-group' }, { children: [_jsx("div", __assign({ className: 'label' }, { children: t(label) })), _jsx("input", { type: type, name: name, value: value, defaultValue: defaultValue, placeholder: t(placeholder), onChange: handleChange, maxLength: maxLength, autoFocus: autoFocus, autoComplete: autoComplete, min: min, max: max, step: step, pattern: pattern })] })));
};
export default InputText;
