import { aliasBranches } from '../../api';
export var ALIAS_BRANCHES_GET_SUCCEEDED = 'ALIAS_BRANCHES_GET_SUCCEEDED';
export var ALIAS_BRANCHES_GET_FAILED = 'ALIAS_BRANCHES_GET_FAILED';
export var getStationAliasBranches = function (stationId) { return function (dispatch) {
    return aliasBranches
        .get(stationId)
        .then(function (res) {
        return dispatch({
            type: ALIAS_BRANCHES_GET_SUCCEEDED,
            data: res,
        });
    })
        .catch(function (err) {
        return dispatch({
            type: ALIAS_BRANCHES_GET_FAILED,
            data: err,
        });
    });
}; };
