var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import t from '../lib/translate';
import { setReportFileFormat } from '../redux/actions/reports';
var DEFAULT_REPORT_FILE_FORMAT = 'XLSX';
var ReportFileFormat = function (_a) {
    var reportFileFormat = _a.reportFileFormat, setReportFileFormat = _a.setReportFileFormat;
    var supportedReportFileFormats = useMemo(function () { return ['XLSX', 'XLS', 'CSV']; });
    var handleFileFormatChange = function (reportFileFormat) {
        setReportFileFormat(reportFileFormat);
    };
    useEffect(function () {
        if (!reportFileFormat) {
            handleFileFormatChange(DEFAULT_REPORT_FILE_FORMAT);
        }
    }, []);
    return (_jsxs("div", __assign({ className: 'select-group' }, { children: [_jsx("div", __assign({ className: 'label' }, { children: t('reports.create.file.format') })), _jsx("select", __assign({ name: 'reportFileFormat', onChange: function (e) { return handleFileFormatChange(e.target.value); }, value: reportFileFormat || '' }, { children: supportedReportFileFormats !== null &&
                    supportedReportFileFormats.map(function (fileFormat) { return (_jsxs("option", __assign({ value: fileFormat }, { children: [".", fileFormat.toLowerCase()] }), fileFormat)); }) })), _jsx("i", { className: 'fa fa-caret-down' })] })));
};
var mapDispatchToProps = {
    setReportFileFormat: setReportFileFormat,
};
var mapStateToProps = function (state) { return ({
    reportFileFormat: state.reports.selectedFileFormat,
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ReportFileFormat);
