var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import t from '../lib/translate';
var BulkLabelInfo = function (_a) {
    var bulkLabel = _a.bulkLabel, printBulkLabel = _a.printBulkLabel;
    var countLimit = bulkLabel.countLimit, timeLimit = bulkLabel.timeLimit, timestamp = bulkLabel.timestamp, labels = bulkLabel.labels;
    var noLimits = !countLimit && !timeLimit;
    var time = function () {
        return timeLimit && timestamp && moment(new Date(timestamp + timeLimit * 1000)).format('HH:mm:ss');
    };
    var info = function () {
        var key = 'scan.bulklabel';
        if (countLimit)
            key += '.countlimit';
        if (timeLimit)
            key += '.timelimit';
        if (noLimits)
            key += labels.length ? '.nolimit' : '.none';
        return t(key, {
            count: labels.length,
            countLimit: countLimit,
            time: time(),
        });
    };
    return (_jsxs("div", __assign({ className: 'bulk-label-info' }, { children: [_jsx("span", { children: t('scan.bulklabel') }), info(), !!labels.length && (_jsx("button", __assign({ type: 'button', onClick: printBulkLabel }, { children: t('scan.bulklabel.print') })))] })));
};
export default BulkLabelInfo;
