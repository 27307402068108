import { jsx as _jsx } from "react/jsx-runtime";
import Scan from './Scan';
import AliasMapping from './AliasMapping';
import LoadCarriersPage from './LoadCarriersPage';
import ReportsPage from './ReportsPage';
export var ROLE_OPERATOR = 'role.scanning.operator';
export var ROLE_ADMIN = 'role.scanning.admin';
export var ROLE_REPORTS = 'role.scanning.reports';
export var ROLE_DI = 'di';
var sections = [
    {
        name: 'scan',
        path: '/scan',
        hasAccess: function () { return true; },
        component: _jsx(Scan, {}),
    },
    {
        name: 'alias.mapping',
        path: '/aliasMapping',
        hasAccess: function () { return true; },
        component: _jsx(AliasMapping, {}),
    },
    {
        name: 'load.carriers',
        path: '/loadCarriers',
        hasAccess: function () { return true; },
        component: _jsx(LoadCarriersPage, {}),
    },
    {
        name: 'reports',
        path: '/reports',
        hasAccess: function (roles) { return roles.some(function (r) { return [ROLE_REPORTS, ROLE_DI].includes(r); }); },
        component: _jsx(ReportsPage, {}),
    },
];
export default sections;
