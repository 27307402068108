var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { merge } from 'lodash';
import * as Sentry from '@sentry/react';
import store from '../redux/store';
import { unauthorized } from '../redux/actions/authentication';
import { readFileToUint18Array } from '../lib/format';
var API_URL = '/api/ui/operator';
var handleError = function (res) { return __awaiter(void 0, void 0, void 0, function () {
    var body, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (res.status === 401) {
                    store.dispatch(unauthorized);
                    localStorage.setItem('token', '');
                    throw new Error(JSON.stringify({ errorKey: 'unauthorized' }));
                }
                return [4 /*yield*/, res.json()];
            case 1:
                body = _a.sent();
                if (body.statusCode)
                    error = JSON.stringify(body);
                if (body.status)
                    error = JSON.stringify({ statusCode: body.status, errorKey: body.message });
                Sentry.captureException(new Error(error));
                throw new Error(error);
        }
    });
}); };
var base64Encode = function (fileStream) {
    return readFileToUint18Array(fileStream)
        .then(function (uint8Array) { return btoa(String.fromCharCode.apply(null, uint8Array)); })
        .then(function (base64) { return "data:audio/mpeg;base64,".concat(base64); });
};
var anyRequest = function (url, additionalOptions) {
    var options = {
        headers: {},
    };
    var jwtToken = localStorage.getItem('jwt-token');
    if (jwtToken) {
        options.headers.Authorization = "Bearer ".concat(jwtToken);
    }
    var excludeFromApiUrlPrefix = ['/login', '/logout', '/api/ui/userinfo'];
    if (!excludeFromApiUrlPrefix.includes(url)) {
        // Login and logout at root
        url = "".concat(API_URL).concat(url);
    }
    url = "".concat(process.env.REACT_APP_BASE_URL).concat(url);
    return fetch(url, merge(options, additionalOptions))
        .catch(function () {
        throw new Error(JSON.stringify({ statusCode: 503, errorKey: 'service.unavailable' }));
    })
        .then(function (res) {
        if (!res.ok) {
            return handleError(res);
        }
        var jwtToken = res.headers.get('x-jwt-token');
        if (jwtToken) {
            localStorage.setItem('jwt-token', jwtToken);
        }
        var contentType = res.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
            return res.json();
        }
        if (contentType && contentType.indexOf('application/octet-stream') !== -1) {
            return base64Encode(res);
        }
        if (contentType && contentType.indexOf('application/pdf') !== -1) {
            return res.blob();
        }
    });
};
var getRequest = function (url, additionalOptions) {
    return anyRequest(url, __assign({ method: 'GET', credentials: 'include' }, additionalOptions));
};
var postRequest = function (url, body, additionalOptions) {
    return anyRequest(url, __assign({ method: 'POST', credentials: 'include', headers: {
            'Content-Type': 'Application/JSON',
        }, body: JSON.stringify(body) }, additionalOptions));
};
var postBlobRequest = function (url, body, stationId, additionalOptions) {
    var formData = new FormData();
    formData.append('img', body);
    formData.append('stationId', stationId);
    return anyRequest(url, __assign({ method: 'POST', credentials: 'include', body: formData }, additionalOptions));
};
var postFormRequest = function (url, jsonBody, additionalOptions) {
    return anyRequest(url, __assign({ method: 'POST', credentials: 'include', headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }, body: Object.keys(jsonBody)
            .map(function (k) { return "".concat(encodeURIComponent(k), "=").concat(encodeURIComponent(jsonBody[k])); })
            .join('&') }, additionalOptions));
};
var putRequest = function (url, body, additionalOptions) {
    return anyRequest(url, __assign({ method: 'PUT', credentials: 'include', headers: {
            'Content-Type': 'Application/JSON',
        }, body: JSON.stringify(body) }, additionalOptions));
};
var deleteRequest = function (url, additionalOptions) {
    return anyRequest(url, __assign({ method: 'DELETE', credentials: 'include', headers: {
            'Content-Type': 'Application/JSON',
        } }, additionalOptions));
};
export { getRequest, postFormRequest, postRequest, putRequest, deleteRequest, postBlobRequest };
