var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Webcam from 'react-webcam';
import React from 'react';
import { connect } from 'react-redux';
import { ocrScan, playAliasSoundFromQueue } from '../redux/actions/scan';
import t from '../lib/translate';
var OCRWebcam = /** @class */ (function (_super) {
    __extends(OCRWebcam, _super);
    function OCRWebcam() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _this = _super.apply(this, args) || this;
        _this.setRef = function (webcam) {
            _this.webcam = webcam;
        };
        _this.captureImage = function () {
            var imgSrc = _this.webcam.getScreenshot();
            _this.setState({ imgSrc: imgSrc });
            _this.props.uploadImage(imgSrc);
        };
        _this.newImage = function () { return _this.setState({ imgSrc: null }); };
        _this.handleClick = function () {
            var imgSrc = _this.state.imgSrc;
            if (_this._isMounted) {
                if (imgSrc !== null) {
                    _this.newImage();
                }
                else {
                    _this.captureImage();
                }
            }
        };
        _this.state = {
            imgSrc: null,
            blobUrl: null,
            blob: null,
        };
        _this.newImage.bind(_this);
        _this.captureImage.bind(_this);
        _this.handleClick.bind(_this);
        _this._isMounted = false;
        return _this;
    }
    OCRWebcam.prototype.componentDidMount = function () {
        var _this = this;
        var handleClick = function () { return _this.handleClick(); };
        this._isMounted = true;
        window.addEventListener('keypress', function (e) {
            if ((e.which || e.keyCode) === 32) {
                handleClick();
            }
        });
    };
    OCRWebcam.prototype.componentWillUnmount = function () {
        this._isMounted = false;
    };
    OCRWebcam.prototype.render = function () {
        var imgSrc = this.state.imgSrc;
        var videoConstraints = {
            facingMode: 'environment',
        };
        return (_jsx("div", __assign({ className: 'cam-wrapper', onClick: imgSrc !== null ? this.newImage : this.captureImage }, { children: imgSrc !== null ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'image-help' }, { children: t('scan.image.help.new') })), _jsx("img", { src: imgSrc, alt: 'scanned package' })] })) : (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'image-help' }, { children: t('scan.image.help') })), _jsx(Webcam, { tabIndex: '0', audio: false, ref: this.setRef, screenshotFormat: 'image/jpeg', videoConstraints: videoConstraints, className: 'webcam', style: { aspectRatio: '4/3' } })] })) })));
    };
    return OCRWebcam;
}(React.Component));
var mapDispatchToProps = {
    playAliasSoundFromQueue: playAliasSoundFromQueue,
    ocrScan: ocrScan,
};
export default connect(null, mapDispatchToProps)(OCRWebcam);
