var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import t from '../lib/translate';
var ReportStatus = function (_a) {
    var report = _a.report;
    if (report.errorMessage === 'no.data') {
        return _jsx("span", __assign({ className: 'report-status info' }, { children: t('reports.status.no.data') }));
    }
    if (report.errorMessage) {
        return _jsx("span", __assign({ className: 'report-status error' }, { children: t('reports.status.error') }));
    }
    if (report.processingStarted == null) {
        return _jsx("span", __assign({ className: 'report-status created' }, { children: t('reports.status.created') }));
    }
    if (report.processingFinished == null) {
        return _jsx("span", __assign({ className: 'report-status processing' }, { children: t('reports.status.processing') }));
    }
    return _jsx("span", __assign({ className: 'report-status finished' }, { children: t('reports.status.finished') }));
};
export default ReportStatus;
