import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import { scanReducer, aliasBranchesReducer, loadCarriersReducer, stationSortingConfigAliasesReducer, loaderReducer, scanModeReducer, stationReducer, stationsReducer, errorReducer, userReducer, parcelCounterReducer, systemInfoReducer, userInfoReducer, reportsReducer, returnCodesReducer, bulkLabelReducer, } from './reducers';
var reducer = combineReducers({
    isLoading: loaderReducer,
    error: errorReducer,
    scan: scanReducer,
    aliasBranches: aliasBranchesReducer,
    sortingConfigAliases: stationSortingConfigAliasesReducer,
    scanMode: scanModeReducer,
    stationId: stationReducer,
    stations: stationsReducer,
    loadCarriers: loadCarriersReducer,
    user: userReducer,
    parcelCounter: parcelCounterReducer,
    systemInfo: systemInfoReducer,
    returnCodes: returnCodesReducer,
    userInfo: userInfoReducer,
    reports: reportsReducer,
    bulkLabel: bulkLabelReducer,
});
var store = createStore(reducer, 
// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
composeWithDevTools(applyMiddleware(thunk)));
export default store;
