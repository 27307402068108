var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import t from '../lib/translate';
var ScanInformation = function (_a) {
    var parcelCounter = _a.parcelCounter, parcelCounterReturn = _a.parcelCounterReturn, returnCompatible = _a.returnCompatible;
    return (_jsx("div", __assign({ className: 'scan-info' }, { children: _jsxs("div", { children: [_jsxs("span", { children: [t('scan.mode.outgoing'), ": ", parcelCounter] }), returnCompatible && (_jsxs("span", { children: [t('scan.mode.return'), ": ", parcelCounterReturn] }))] }) })));
};
export default ScanInformation;
