var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { fill } from 'lodash';
import { ALIAS_GET_SUCCEEDED, ALIAS_GET_FAILED, ALIAS_SOUND_PLAYING, ALIAS_SOUND_DONE, } from '../actions/scan';
var HISTORY_LENGTH = 3;
var INITIAL_ALIAS = {
    alias: null,
    trackingReference: null,
};
var INITIAL_STATE = {
    current: INITIAL_ALIAS,
    history: fill(Array(HISTORY_LENGTH), INITIAL_ALIAS),
    aliasSoundPlaying: false,
    aliasQueue: [],
};
var parse = function (data) { return JSON.parse(data.message); };
var buildAliasHistory = function (state) {
    if (state.current.alias === null) {
        return state.history;
    }
    return __spreadArray([state.current], state.history.slice(0, HISTORY_LENGTH - 1), true);
};
var scanReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case ALIAS_GET_SUCCEEDED:
            return __assign(__assign({}, state), { aliasQueue: __spreadArray(__spreadArray([], state.aliasQueue, true), [action.data], false) });
        case ALIAS_GET_FAILED:
            return __assign(__assign({}, state), { aliasQueue: __spreadArray(__spreadArray([], state.aliasQueue, true), [
                    {
                        alias: parse(action.data.alias),
                        trackingReference: action.data.trackingReference,
                    },
                ], false) });
        case ALIAS_SOUND_PLAYING:
            return __assign(__assign({}, state), { aliasSoundPlaying: true, history: buildAliasHistory(state), current: state.aliasQueue[0], aliasQueue: state.aliasQueue.slice(1, state.aliasQueue.length) });
        case ALIAS_SOUND_DONE:
            return __assign(__assign({}, state), { aliasSoundPlaying: false });
        default:
            return state;
    }
};
export default scanReducer;
