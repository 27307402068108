var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import t from '../../../lib/translate';
export var errorHandler = function (_a) {
    var errorKey = _a.errorKey, statusCode = _a.statusCode, errorMap = _a.errorMap;
    var i18nErrorKey = "err.".concat(errorKey);
    var statusCodeString = statusCode ? " (".concat(statusCode, ")") : '';
    if (!errorKey.startsWith('validation.'))
        return "".concat(t(i18nErrorKey)).concat(statusCodeString);
    var translatedParams = errorMap.errorMsgParams.map(function (param) { return t(param); });
    return "".concat(t.apply(void 0, __spreadArray([i18nErrorKey], translatedParams, false))).concat(statusCodeString);
};
