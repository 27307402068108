var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import toArray from 'lodash/toArray';
import t, { languages } from '../../lib/translate';
import { changeLanguage } from '../../redux/actions';
import ReturnScanOptions from '../ReturnScanOptions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
var tooltipDismissOutsideClick = function (settingsMenuOpen, toggleSettingsMenu) {
    window.addEventListener('click', function (event) {
        var notTooltip = !toArray(event.target.classList).includes('inside-tooltip');
        if (settingsMenuOpen && notTooltip)
            toggleSettingsMenu(!settingsMenuOpen);
    });
};
var SettingsMenu = function (_a) {
    var _b = _a.mobile, mobile = _b === void 0 ? false : _b;
    var _c = useState(false), settingsMenuOpen = _c[0], toggleSettingsMenu = _c[1];
    var dispatch = useAppDispatch();
    var selectedLanguage = useAppSelector(function (state) { return state.user.language; });
    tooltipDismissOutsideClick(settingsMenuOpen, toggleSettingsMenu);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "settings inside-tooltip ".concat(mobile ? 'mobile-icons' : 'desktop'), onClick: function (e) {
                    e.stopPropagation();
                    toggleSettingsMenu(!settingsMenuOpen);
                } }, { children: [_jsx("i", { className: 'fas fa-cog inside-tooltip' }), !mobile && t('header.settings')] })), settingsMenuOpen && (_jsxs("div", __assign({ className: "tooltip inside-tooltip ".concat(mobile ? 'right' : '') }, { children: [_jsxs("div", __assign({ className: 'languages inside-tooltip' }, { children: [_jsx("label", { children: t('header.language') }), languages.map(function (language) { return (_jsx("button", __assign({ className: language.value === selectedLanguage ? 'active' : '', onClick: function (e) {
                                    e.stopPropagation();
                                    dispatch(changeLanguage(language.value));
                                } }, { children: language.label }), language.value)); })] })), _jsx("div", __assign({ className: 'settings-wrapper inside-tooltip' }, { children: _jsx(ReturnScanOptions, {}) }))] })))] }));
};
export default SettingsMenu;
