var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import t from '../lib/translate';
import ReportStatus from '../components/ReportStatus';
import Loader from '../components/Loader';
import { getReports } from '../redux/actions/reports';
import CreateReportForm from './CreateReportForm';
import ErrorMessage from '../components/ErrorMessage';
import StationBadges from './StationBadges';
var DATE_FORMAT = 'DD.MM.yyyy HH:mm';
var ReportsPage = function (_a) {
    var reports = _a.reports, error = _a.error, loading = _a.loading, getReports = _a.getReports;
    useEffect(function () {
        getReports();
        var interval = setInterval(function () {
            getReports();
        }, 60 * 1000);
        return function () { return clearInterval(interval); };
    }, []);
    var downloadLink = function (reportId) {
        return "".concat(process.env.REACT_APP_BASE_URL, "/api/ui/reports/download/").concat(reportId);
    };
    return (_jsxs("div", __assign({ className: 'reports-page' }, { children: [loading && _jsx(Loader, {}), _jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'reports-page-block' }, { children: [_jsxs("div", __assign({ className: 'page-info' }, { children: [_jsx("div", __assign({ className: 'page-header' }, { children: t('reports.header') })), _jsx("div", __assign({ className: 'page-description' }, { children: t('reports.description') }))] })), error && _jsx(ErrorMessage, { message: t("reports.errors.".concat(error.errorKey)) }), _jsx(CreateReportForm, {})] })), _jsxs("div", __assign({ className: 'reports-page-block' }, { children: [_jsxs("div", __assign({ className: 'page-info' }, { children: [_jsx("div", __assign({ className: 'page-header' }, { children: t('reports.history.header') })), _jsx("div", __assign({ className: 'page-description' }, { children: t('reports.history.description') }))] })), _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: t('reports.table.headers.from') }), _jsx("th", { children: t('reports.table.headers.to') }), _jsx("th", { children: t('reports.table.headers.stations') }), _jsx("th", { children: t('reports.table.headers.status') }), _jsx("th", {})] }) }), _jsx("tbody", { children: !loading &&
                                            (reports === null || reports === void 0 ? void 0 : reports.map(function (report) { return (_jsxs("tr", { children: [_jsx("td", __assign({ label: t('reports.table.headers.from') }, { children: moment(report.fromDate).format(DATE_FORMAT) })), _jsx("td", __assign({ label: t('reports.table.headers.to') }, { children: moment(report.toDate).format(DATE_FORMAT) })), _jsx("td", __assign({ label: t('reports.table.headers.stations'), className: 'stations' }, { children: _jsx(StationBadges, { stationIds: report.stationIds }) })), _jsx("td", __assign({ label: t('reports.table.headers.status') }, { children: _jsx(ReportStatus, { report: report }) })), _jsxs("td", { children: [report.errorMessage && t("reports.errors.".concat(report.errorMessage)), report.processingFinished && (_jsxs("a", __assign({ className: 'download-report', href: downloadLink(report.reportId), target: '_blank', rel: 'noreferrer' }, { children: [_jsx("i", { className: 'fas fa-download' }), " ", t('reports.table.download')] })))] })] }, report.reportId)); })) })] })] }))] })] })));
};
var mapDispatchToProps = {
    getReports: getReports,
};
var mapStateToProps = function (state) { return ({
    loading: state.isLoading,
    reports: state.reports.reports,
    error: state.reports.error,
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
