export var formatDate = function (date) { return "".concat(date.slice(0, 4), "-").concat(date.slice(4, 6), "-").concat(date.slice(6, 8)); };
export var readFileToUint18Array = function (res) {
    return new Promise(function (resolve) {
        var result;
        var reader = res.body.getReader();
        var processText = function (_a) {
            var done = _a.done, value = _a.value;
            if (done) {
                return resolve(result);
            }
            if (result) {
                // Concat
                res = new Uint8Array(result.length + value.length);
                res.set(result);
                res.set(value, result.length);
                result = res;
            }
            else {
                result = value;
            }
            return reader.read().then(processText);
        };
        reader.read().then(processText);
    });
};
export var base64toBlob = function (b64Data, sliceSize) {
    if (sliceSize === void 0) { sliceSize = 512; }
    var byteCharacters = window.atob(b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: 'image/jpeg' });
};
