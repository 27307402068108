var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Loader from './Loader';
var LoadingButton = function (_a) {
    var label = _a.label, isLoading = _a.isLoading, onClick = _a.onClick, disabled = _a.disabled;
    return (_jsx("button", __assign({ type: 'submit', className: isLoading ? 'loading' : '', onClick: onClick, disabled: disabled }, { children: isLoading ? _jsx(Loader, { button: true }) : label })));
};
export default LoadingButton;
