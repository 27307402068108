import { userInfo } from '../../api';
export var GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export var GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';
export var getUserInfo = function () { return function (dispatch) {
    return userInfo
        .get()
        .then(function (userInfo) {
        dispatch({
            type: GET_USER_INFO_SUCCESS,
            data: userInfo,
        });
    })
        .catch(function (err) {
        return dispatch({
            type: GET_USER_INFO_FAILURE,
            data: err,
        });
    });
}; };
