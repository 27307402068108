import * as api from '../../api';
import { setLoader } from './loader';
import { resetErrors } from './resetErrors';
export var LOAD_CARRIERS_RESET = 'LOAD_CARRIERS_RESET';
export var LOAD_CARRIERS_CREATE_SUCCEEDED = 'LOAD_CARRIERS_CREATE_SUCCEEDED';
export var LOAD_CARRIERS_CREATE_FAILED = 'LOAD_CARRIERS_CREATE_FAILED';
export var resetLoadCarriers = function () { return function (dispatch) {
    return dispatch({
        type: LOAD_CARRIERS_RESET,
    });
}; };
export var createLoadCarriers = function (stationId, loadCarriers) { return function (dispatch) {
    dispatch(setLoader(true));
    dispatch(resetErrors());
    return api.loadCarriers
        .create(stationId, loadCarriers)
        .then(function (res) {
        return dispatch({
            type: LOAD_CARRIERS_CREATE_SUCCEEDED,
            data: res,
        });
    })
        .catch(function (err) {
        return dispatch({
            type: LOAD_CARRIERS_CREATE_FAILED,
            data: err,
        });
    })
        .finally(function () { return dispatch(setLoader(false)); });
}; };
