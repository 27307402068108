var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import t from '../lib/translate';
var NotFound = function (_a) {
    var message = _a.message;
    return (_jsx("div", __assign({ className: 'page-not-found' }, { children: _jsxs("div", __assign({ className: 'illustration' }, { children: [_jsx("div", { children: "404" }), message || t('err.page.not.found')] })) })));
};
export default NotFound;
