var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Error from './Error';
import LoadingButton from './LoadingButton';
import t from '../lib/translate';
var handleSubmit = function (onSubmit, e) {
    e.preventDefault();
    onSubmit();
};
var Form = function (_a) {
    var isLoading = _a.isLoading, error = _a.error, enableSubmit = _a.enableSubmit, onClose = _a.onClose, onSubmit = _a.onSubmit, hideButtons = _a.hideButtons, buttonLabel = _a.buttonLabel, hideCancel = _a.hideCancel, _b = _a.className, className = _b === void 0 ? '' : _b, children = _a.children;
    return (_jsxs("form", __assign({ className: "form ".concat(isLoading ? 'disabled' : '', " ").concat(className), onSubmit: handleSubmit.bind(null, onSubmit) }, { children: [error && _jsx(Error, { error: error }), children, !hideButtons && (_jsxs("div", __assign({ className: 'button-group' }, { children: [_jsx("button", __assign({ className: "cancel".concat(hideCancel ? ' hidden' : ''), onClick: onClose }, { children: t('button.cancel') })), _jsx(LoadingButton, { label: t(buttonLabel !== null && buttonLabel !== void 0 ? buttonLabel : 'button.submit'), isLoading: isLoading, onClick: handleSubmit.bind(null, onSubmit), disabled: !enableSubmit })] })))] })));
};
export default Form;
