var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { connect } from 'react-redux';
import Loader from '../components/Loader';
import Error from '../components/Error';
import ConfigBar from './ConfigBar';
import t from '../lib/translate';
import DayMatrixLabel from '../components/DayMatrixLabel';
var AliasMapping = function (_a) {
    var aliasBranches = _a.aliasBranches, sortingConfigAliases = _a.sortingConfigAliases, stationId = _a.stationId, loading = _a.loading, error = _a.error;
    return (_jsxs("div", __assign({ className: 'alias-mapping' }, { children: [loading && _jsx(Loader, {}), _jsx(ConfigBar, {}), _jsxs("div", __assign({ className: "mapping".concat(loading ? ' loader-background' : '') }, { children: [error && _jsx(Error, { error: error }), stationId ? (_jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("td", { children: t('alias.paths.label.alias') }), _jsx("td", { children: t('alias.paths.label.external.id') })] }) }), _jsxs("tbody", { children: [aliasBranches.map(function (aliasBranch) { return (_jsxs("tr", { children: [_jsx("td", { children: aliasBranch.alias }), _jsx("td", { children: aliasBranch.branches.map(function (branch) { return (_jsxs("div", { children: ["(".concat(branch.startNode.nodeTypeCode, ") ").concat(branch.startNode.label), _jsx("i", { className: 'fas fa-arrow-right' }), "(".concat(branch.endNode.nodeTypeCode, ") ").concat(branch.endNode.label), _jsx(DayMatrixLabel, { dayMatrix: branch.endNode.dayMatrix, nodeTypeCode: branch.endNode.nodeTypeCode })] }, "".concat(branch.endNode.nodeId, "-").concat(branch.startNode.nodeId))); }) })] }, aliasBranch.alias)); }), sortingConfigAliases.map(function (sortingConfigAlias) { return (_jsxs("tr", { children: [_jsx("td", { children: sortingConfigAlias.alias }), _jsx("td", { children: sortingConfigAlias.name })] }, sortingConfigAlias.alias)); })] })] })) : (_jsx("div", __assign({ className: 'config-message' }, { children: t('alias.paths.choose.station') })))] }))] })));
};
var mapStateToProps = function (state) { return ({
    aliasBranches: state.aliasBranches,
    sortingConfigAliases: state.sortingConfigAliases.data,
    stationId: state.stationId,
    loading: state.isLoading,
    error: state.error.aliasMapping,
}); };
export default connect(mapStateToProps)(AliasMapping);
