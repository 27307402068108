var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LOAD_CARRIERS_RESET, LOAD_CARRIERS_CREATE_SUCCEEDED, LOAD_CARRIERS_CREATE_FAILED, } from '../actions/loadCarriers';
var INITIAL_STATE = {
    internal: [],
    external: undefined,
};
var loadCarriersReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case LOAD_CARRIERS_CREATE_SUCCEEDED:
            return __assign(__assign({}, state), { internal: action.data });
        case LOAD_CARRIERS_CREATE_FAILED:
            return __assign(__assign({}, state), { internal: INITIAL_STATE.internal });
        case LOAD_CARRIERS_RESET:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default loadCarriersReducer;
