var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import { formatDate } from '../lib/format';
import t from '../lib/translate';
var originAliasPath = function (loadCarrier) { return loadCarrier.aliasPaths[0][0]; };
var removeOrigin = function (aliasPath) { return aliasPath.slice(1, aliasPath.length); };
var LoadCarriersPrint = function (_a) {
    var loadCarriers = _a.loadCarriers;
    return (_jsx("div", __assign({ className: 'load-carriers-print' }, { children: loadCarriers.map(function (loadCarrier, index) { return (_jsxs("div", __assign({ className: 'load-carrier-print-page' }, { children: [_jsxs("div", __assign({ className: 'load-carrier-top-wrapper' }, { children: [_jsx("div", __assign({ className: 'load-carrier-section-from' }, { children: _jsxs("div", { children: [_jsx("div", __assign({ className: 'load-carrier-uppercase' }, { children: t('lc.label.from') })), _jsxs("div", { children: [t("lc.label.nodetypecode.".concat(originAliasPath(loadCarrier).nodeTypeCode), originAliasPath(loadCarrier).label), _jsxs("div", __assign({ className: 'load-carrier-alias' }, { children: ["(", loadCarrier.alias, ")"] }))] })] }) })), _jsx("div", __assign({ className: 'load-carrier-qr-code' }, { children: _jsx(QRCode, { value: loadCarrier.parcelNumber }) }))] })), _jsxs("div", __assign({ className: 'load-carrier-section-main' }, { children: [_jsxs("div", __assign({ className: 'load-carrier-section-to' }, { children: [_jsx("div", { children: loadCarrier.aliasPaths.map(function (aliasPath, index) { return (_jsxs("div", __assign({ className: 'load-carrier-path' }, { children: [loadCarrier.aliasPaths.length === 1
                                                ? t('lc.label.path', '')
                                                : t('lc.label.path', " ".concat(index + 1)), removeOrigin(aliasPath).map(function (route) { return (_jsx("div", __assign({ className: 'load-carrier-path-node' }, { children: t("lc.label.nodetypecode.".concat(route.nodeTypeCode), route.label) }), route.nodeId)); })] }), index)); }) }), _jsx("div", { children: t('lc.label.tracking.reference.sub', loadCarrier.parcelNumber.substr(loadCarrier.parcelNumber.length - 4, loadCarrier.parcelNumber.length)) })] })), _jsx("div", __assign({ className: 'load-carrier-free-text' }, { children: loadCarrier.aliasFreeText }))] })), _jsxs("div", __assign({ className: 'load-carrier-barcode' }, { children: [_jsx(Barcode, { value: loadCarrier.parcelNumber, width: 6, height: 240, displayValue: false }), _jsx("span", { children: loadCarrier.presentableParcelNumber })] })), _jsxs("div", __assign({ className: 'load-carrier-date' }, { children: [t('lc.label.date'), " ", formatDate(loadCarrier.generatedDateString)] }))] }), index)); }) })));
};
export default LoadCarriersPrint;
