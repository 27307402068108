var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector } from '../redux/hooks';
var StationBadges = function (_a) {
    var stationIds = _a.stationIds;
    var stations = useAppSelector(function (state) { return state.stations; });
    var includedStations = stations === null || stations === void 0 ? void 0 : stations.filter(function (_a) {
        var stationId = _a.stationId;
        return stationIds === null || stationIds === void 0 ? void 0 : stationIds.includes(stationId);
    });
    return (_jsx("div", __assign({ className: 'station-badges' }, { children: includedStations === null || includedStations === void 0 ? void 0 : includedStations.map(function (station) { return (_jsx("span", __assign({ className: 'station-badge' }, { children: station.name }), station.stationId)); }) })));
};
export default StationBadges;
