var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { connect } from 'react-redux';
import { resetErrors } from '../redux/actions/resetErrors';
import { setLoader } from '../redux/actions/loader';
import { getStationAliasBranches } from '../redux/actions/aliasBranches';
import { setStationId } from '../redux/actions/station';
import { getParcelCounter } from '../redux/actions/parcelCounter';
import { getSortingConfigAliases } from '../redux/actions/stationSortingConfigAliases';
import t from '../lib/translate';
import { setMode } from '../redux/actions/scanMode';
import * as amplitude from '@amplitude/analytics-browser';
var ConfigBar = function (_a) {
    var stationId = _a.stationId, stations = _a.stations, getStationAliasBranches = _a.getStationAliasBranches, getSortingConfigAliases = _a.getSortingConfigAliases, getParcelCounter = _a.getParcelCounter, scanMode = _a.scanMode, includeMode = _a.includeMode, withLabels = _a.withLabels, setMode = _a.setMode, returnCompatible = _a.returnCompatible, setStationId = _a.setStationId, resetErrors = _a.resetErrors, setLoader = _a.setLoader;
    var handleChangeStation = function (stationId) {
        var returnCompatible = stations.find(function (station) { return station.stationId === stationId; }).returnCompatible;
        setStationId(stationId);
        if (!returnCompatible && scanMode === 'return') {
            setMode('outgoing');
        }
        resetErrors();
        setLoader(true);
        Promise.all([
            getStationAliasBranches(stationId),
            getParcelCounter(stationId),
            getSortingConfigAliases(stationId),
        ]).then(function () { return setLoader(false); });
    };
    var handleOnClick = function (mode) {
        if (mode === void 0) { mode = 'info'; }
        amplitude.track('Info Scan Accessed', {
            source: 'config bar button',
        });
        setMode(mode);
    };
    return (_jsxs("div", __assign({ className: 'config-bar' }, { children: [_jsxs("div", __assign({ className: 'select-group' }, { children: [withLabels && _jsx("div", __assign({ className: 'label' }, { children: t('select.default.station') })), _jsxs("select", __assign({ name: 'stationId', onChange: function (e) { return handleChangeStation(+e.target.value); }, value: stationId || '' }, { children: [!stationId && _jsx("option", { children: t('select.default.station') }), stations &&
                                stations.map(function (station) { return (_jsx("option", __assign({ value: station.stationId, "data-return": station.returnCompatible }, { children: station.name }), station.stationId)); })] })), _jsx("i", { className: 'fa fa-caret-down' })] })), includeMode && (_jsxs(_Fragment, { children: [_jsx("button", __assign({ type: 'button', className: scanMode === 'outgoing' ? 'active' : '', onClick: function () { return setMode('outgoing'); }, disabled: scanMode === 'outgoing' }, { children: t('scan.mode.outgoing') })), returnCompatible && (_jsx("button", __assign({ type: 'button', className: scanMode === 'return' ? 'active' : '', onClick: function () { return setMode('return'); }, disabled: scanMode === 'return' }, { children: t('scan.mode.return') }))), _jsx("button", __assign({ type: 'button', className: scanMode === 'info' ? 'active' : '', onClick: function () { return handleOnClick('info'); }, disabled: scanMode === 'info' }, { children: t('scan.mode.info') }))] }))] })));
};
var mapDispatchToProps = {
    getStationAliasBranches: getStationAliasBranches,
    getSortingConfigAliases: getSortingConfigAliases,
    getParcelCounter: getParcelCounter,
    setMode: setMode,
    setStationId: setStationId,
    resetErrors: resetErrors,
    setLoader: setLoader,
};
var mapStateToProps = function (state) {
    var station = state.stations.find(function (station) { return station.stationId === state.stationId; });
    return {
        stationId: state.stationId,
        stations: state.stations,
        scanMode: state.scanMode,
        language: state.user.language,
        returnCompatible: station ? station.returnCompatible : true,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfigBar);
