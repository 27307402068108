// Force JS to use the proper time zone
export var changeTimeZone = function (date) {
    var utc = new Date(date.toLocaleString('en-US', {
        timeZone: 'UTC',
    }));
    var diff = date.getTime() - utc.getTime();
    return new Date(date.getTime() + diff);
};
export var simpleHash = function (str) {
    if (!str) {
        return '';
    }
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        var char = str.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + char;
        // eslint-disable-next-line no-bitwise
        hash &= hash;
    }
    return new Uint32Array([hash])[0].toString(36);
};
