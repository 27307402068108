import { CHANGE_STATION_ID } from '../actions/station';
import { ALIAS_BRANCHES_GET_SUCCEEDED, ALIAS_BRANCHES_GET_FAILED } from '../actions/aliasBranches';
var aliasBranchesReducer = function (state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case ALIAS_BRANCHES_GET_SUCCEEDED:
            return action.data;
        case CHANGE_STATION_ID:
        case ALIAS_BRANCHES_GET_FAILED:
            return [];
        default:
            return state;
    }
};
export default aliasBranchesReducer;
