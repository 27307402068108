var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import sections from './sections';
import SettingsMenu from '../components/SettingsMenu';
import t from '../lib/translate';
import EnvironmentRibbon from '../components/EnvironmentRibbon';
import Logo from '../images/Cargostream.svg';
import LaunchNotesButton from '../components/LaunchNotesButton';
var Header = function (_a) {
    var admin = _a.admin, grantedRoles = _a.grantedRoles, logout = _a.logout;
    var _b = useState(false), headerExpanded = _b[0], toggleExpandHeader = _b[1];
    return (_jsxs("header", __assign({ className: headerExpanded ? 'expanded' : '' }, { children: [_jsx(EnvironmentRibbon, {}), _jsx(Link, __assign({ to: '/', className: 'brand' }, { children: _jsx(Logo, { preserveAspectRatio: 'xMinYMid meet', viewBox: '0 -5 65 65' }) })), _jsxs("div", __assign({ className: 'header-icons' }, { children: [_jsx(SettingsMenu, { mobile: true }), _jsx("div", { className: "fas ".concat(headerExpanded ? 'fa-times' : 'fa-bars'), onClick: function () {
                            toggleExpandHeader(!headerExpanded);
                        } })] })), _jsxs("div", __assign({ className: 'menu' }, { children: [_jsx("div", __assign({ className: 'navigation' }, { children: sections
                            .filter(function (section) { return section.hasAccess(grantedRoles); })
                            .map(function (section) { return (_jsxs(NavLink, __assign({ to: section.path, onClick: function () {
                                toggleExpandHeader(!headerExpanded);
                            } }, { children: [t("header.".concat(section.name)), _jsx("i", { className: 'fas fa-chevron-right mobile-icons' })] }), section.name)); }) })), _jsxs("div", __assign({ className: 'actions' }, { children: [_jsx(LaunchNotesButton, {}), admin && (_jsx(_Fragment, { children: _jsxs("a", __assign({ href: process.env.REACT_APP_ADMIN_URL, target: '_blank', rel: 'noopener noreferrer', className: 'admin-link' }, { children: [t('header.admin'), _jsx("i", { className: 'fas fa-external-link-square-alt mobile-icons' })] })) })), _jsx(SettingsMenu, {}), _jsxs("div", __assign({ className: 'logout-button', onClick: logout }, { children: [t('button.label.logout'), _jsx("i", { className: 'fas fa-sign-out-alt mobile-icons' })] }))] }))] }))] })));
};
export default Header;
