import { CHANGE_STATION_ID } from '../actions/station';
var stationReducer = function (state, action) {
    if (state === void 0) { state = null; }
    switch (action.type) {
        case CHANGE_STATION_ID:
            return action.data;
        default:
            return state;
    }
};
export default stationReducer;
