var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
var InputDatePicker = function (_a) {
    var name = _a.name, format = _a.format, _b = _a.label, label = _b === void 0 ? name : _b, value = _a.value, onChange = _a.onChange, minDate = _a.minDate, maxDate = _a.maxDate;
    return (_jsxs("div", __assign({ className: 'input-datepicker' }, { children: [_jsx("div", __assign({ className: 'label' }, { children: label })), _jsx(DayPickerInput, { placeholder: 'YYYY-MM-DD', format: format, dayPickerProps: { disabledDays: { before: minDate, after: maxDate } }, value: value, onDayChange: onChange })] })));
};
export default InputDatePicker;
