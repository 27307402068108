var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GET_PARCEL_COUNTER_SUCCEEDED, PARCEL_COUNTER_INCREASE_OUTGOING, PARCEL_COUNTER_INCREASE_RETURN, } from '../actions/parcelCounter';
var INITIAL_STATE = {
    outgoing: 0,
    return: 0,
};
var parcelCounterReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case GET_PARCEL_COUNTER_SUCCEEDED:
            return {
                outgoing: action.data.parcelCounterOutgoing,
                return: action.data.parcelCounterReturn,
            };
        case PARCEL_COUNTER_INCREASE_OUTGOING:
            return __assign(__assign({}, state), { outgoing: state.outgoing + 1 });
        case PARCEL_COUNTER_INCREASE_RETURN:
            return __assign(__assign({}, state), { return: state.return + 1 });
        default:
            return state;
    }
};
export default parcelCounterReducer;
