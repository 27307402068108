var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import t from '../../lib/translate';
var getClassName = function (error) {
    var textLength = error.errorKey.length;
    // if (textLength > 0) return 'xsmall';
    if (textLength > 25)
        return 'small';
    if (textLength >= 16)
        return 'medium';
    if (textLength <= 12)
        return 'large';
};
var AliasError = function (_a) {
    var error = _a.error;
    return (_jsx("div", __assign({ className: 'alias-error' }, { children: _jsx("div", __assign({ className: getClassName(error) }, { children: t("scanerr.".concat(error.errorKey)) })) })));
};
export default AliasError;
