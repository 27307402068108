var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import Select from 'react-select';
import Loader from '../components/Loader';
import Error from '../components/Error';
import LoadCarrierWrapper from '../components/LoadCarrierWrapper';
import ConfigBar from './ConfigBar';
import LoadCarriersPrint from './LoadCarriersPrint';
import { createLoadCarriers, resetLoadCarriers } from '../redux/actions/loadCarriers';
import t from '../lib/translate';
var MAX_LC_PER_ALIAS = 30;
var MIN_LC_PER_ALIAS = 1;
var INITIAL_STATE = {
    selectedAliases: [],
    loadCarriersPerAlias: '',
};
var LoadCarriersPage = /** @class */ (function (_super) {
    __extends(LoadCarriersPage, _super);
    function LoadCarriersPage() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _this = _super.apply(this, args) || this;
        _this.addSelect = function (selectedAliases) {
            _this.setState({ selectedAliases: selectedAliases });
        };
        _this.toggleSelectAll = function (e) {
            e.preventDefault();
            var selectedAliases = _this.state.selectedAliases.length === 0 ? _this.props.aliases : [];
            _this.setState({ selectedAliases: selectedAliases });
        };
        _this.handleChangeNumber = function (event) {
            var number = event.target.value;
            var controlledNumber = number === '' ? '' : Math.min(MAX_LC_PER_ALIAS, Math.max(MIN_LC_PER_ALIAS, number));
            _this.setState({ loadCarriersPerAlias: controlledNumber });
        };
        _this.enableSubmit = function () {
            return !!(!_this.props.loading &&
                _this.props.stationId &&
                _this.state.selectedAliases.length > 0 &&
                _this.state.loadCarriersPerAlias !== '');
        };
        _this.handleSubmit = function (event) {
            event.preventDefault();
            if (_this.enableSubmit()) {
                var req = {
                    aliases: _this.state.selectedAliases.map(function (selected) { return selected.value; }),
                    number: _this.state.loadCarriersPerAlias,
                };
                _this.setState(INITIAL_STATE);
                _this.props.createLoadCarriers(_this.props.stationId, req).then(function () { return _this.openPrintDialogue(); });
            }
        };
        _this.openPrintDialogue = function () {
            _this.props.loadCarriers.length > 0 && window.print();
        };
        _this.handlePrintIframe = function () {
            var iframe = _this.labelFrame.current;
            iframe.focus();
            iframe.contentWindow.print();
        };
        _this.state = INITIAL_STATE;
        _this.labelFrame = React.createRef();
        return _this;
    }
    LoadCarriersPage.prototype.componentWillUnmount = function () {
        this.props.resetLoadCarriers();
    };
    LoadCarriersPage.prototype.render = function () {
        var _a = this.props, loading = _a.loading, error = _a.error, stationId = _a.stationId, aliases = _a.aliases, loadCarriers = _a.loadCarriers;
        var _b = this.state, selectedAliases = _b.selectedAliases, loadCarriersPerAlias = _b.loadCarriersPerAlias;
        return (_jsxs("div", __assign({ className: 'load-carriers-page' }, { children: [_jsx(ConfigBar, {}), loading && _jsx(Loader, {}), _jsxs("div", __assign({ className: classNames('load-carriers-content', {
                        'loader-background': loading,
                    }) }, { children: [_jsxs("div", __assign({ className: 'page-info' }, { children: [_jsx("div", __assign({ className: 'page-header' }, { children: t('load.carriers.header') })), error && _jsx(Error, { error: error }), !stationId && _jsx("div", { children: t('load.carriers.choose.station') })] })), _jsx(LoadCarrierWrapper, __assign({ name: 'load.carriers.internal', onClick: this.openPrintDialogue, disabled: loadCarriers.length === 0 || loading, statusMessage: loadCarriers.length > 0 && t('load.carriers.generated', loadCarriers.length) }, { children: _jsxs("form", __assign({ className: 'load-carrier-form' }, { children: [_jsxs("div", __assign({ className: 'form-group-inline' }, { children: [_jsxs("div", __assign({ className: 'form-group' }, { children: [_jsxs("div", __assign({ className: 'react-select-select-all' }, { children: [_jsx("div", __assign({ className: 'label' }, { children: t('load.carriers.label.select.alias') })), _jsx("span", __assign({ onClick: this.toggleSelectAll }, { children: t(selectedAliases.length === 0
                                                                    ? 'load.carriers.label.select.all'
                                                                    : 'load.carriers.label.empty') }))] })), _jsx("div", __assign({ className: 'select-group' }, { children: _jsx(Select, { isMulti: true, isDisabled: !stationId || loading, value: selectedAliases, options: aliases, onChange: this.addSelect, placeholder: '', className: 'react-select-parent', classNamePrefix: 'react-select' }) }))] })), _jsxs("div", __assign({ className: 'form-group' }, { children: [_jsx("div", __assign({ className: 'label' }, { children: t('load.carriers.label.number') })), _jsx("input", { type: 'number', value: loadCarriersPerAlias, onChange: this.handleChangeNumber, disabled: !stationId || loading })] }))] })), _jsx("div", __assign({ className: 'form-group' }, { children: _jsx("button", __assign({ type: 'submit', onClick: this.handleSubmit, disabled: !this.enableSubmit() }, { children: t('load.carriers.internal.create') })) }))] })) })), _jsx(LoadCarriersPrint, { loadCarriers: loadCarriers })] }))] })));
    };
    return LoadCarriersPage;
}(Component));
var mapDispatchToProps = {
    createLoadCarriers: createLoadCarriers,
    resetLoadCarriers: resetLoadCarriers,
};
var mapStateToProps = function (state) { return ({
    aliases: state.aliasBranches.map(function (aliasBranch) { return ({
        value: aliasBranch.alias,
        label: aliasBranch.alias,
    }); }),
    stationId: state.stationId,
    stations: state.stations,
    loadCarriers: state.loadCarriers.internal,
    loading: state.isLoading,
    error: state.error.loadCarriers || state.error.aliasMapping,
}); };
export default connect(mapStateToProps, mapDispatchToProps)(LoadCarriersPage);
