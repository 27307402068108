var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import t from '../lib/translate';
// TODO Handle error
var ErrorPage = function (_a) {
    var error = _a.error, message = _a.message;
    return (_jsx("div", __assign({ className: 'error-page' }, { children: _jsxs("div", __assign({ className: 'error-wrapper' }, { children: [_jsx("div", __assign({ className: 'error-type' }, { children: t(error.errorKey) })), message && _jsx("div", __assign({ className: 'error-message' }, { children: t(message) }))] })) })));
};
export default ErrorPage;
