import { returnCodes } from '../../api';
export var GET_RETURN_CODES_SUCCESS = 'GET_RETURN_CODES_SUCCESS';
export var GET_RETURN_CODES_FAILURE = 'GET_RETURN_CODES_FAILURE';
export var getReturnCodes = function () { return function (dispatch) {
    return returnCodes
        .get()
        .then(function (returnCode) {
        dispatch({
            type: GET_RETURN_CODES_SUCCESS,
            data: returnCode,
        });
    })
        .catch(function (err) {
        return dispatch({
            type: GET_RETURN_CODES_FAILURE,
            data: err,
        });
    });
}; };
