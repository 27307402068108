import { SET_LOADER } from '../actions/loader';
var loader = function (state, action) {
    if (state === void 0) { state = false; }
    switch (action.type) {
        case SET_LOADER:
            return action.data;
        default:
            return state;
    }
};
export default loader;
