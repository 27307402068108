var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createReport } from '../redux/actions/reports';
import t from '../lib/translate';
import moment from 'moment';
import ReportFileFormat from '../components/ReportFileFormat';
import InputText from '../components/InputText';
import LoadingButton from '../components/LoadingButton';
import InputDayPicker from '../components/InputDayPicker';
import SelectStations from './SelectStations';
var DATE_FORMAT = 'YYYY-MM-DD';
var DATE_LIMIT_DAYS = 45;
var DATE_RANGE_LIMIT_DAYS = 7;
var splitTime = function (timeString) {
    var parts = timeString.split(':');
    return {
        hour: parseInt(parts[0], 10),
        minute: parseInt(parts[1], 10),
    };
};
var clockPattern = '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';
var CreateReportForm = function (_a) {
    var loading = _a.loading, createReport = _a.createReport, defaultEmail = _a.defaultEmail, reportFileFormat = _a.reportFileFormat;
    var _b = useState(new Date()), fromDate = _b[0], setFromDate = _b[1];
    var _c = useState('00:00'), fromTime = _c[0], setFromTime = _c[1];
    var _d = useState(moment(new Date()).add(1, 'd').toDate()), toDate = _d[0], setToDate = _d[1];
    var _e = useState('00:00'), toTime = _e[0], setToTime = _e[1];
    var _f = useState(''), receiverEmail = _f[0], setReceiverEmail = _f[1];
    var _g = useState([]), stations = _g[0], setStations = _g[1];
    useEffect(function () { return defaultEmail && setReceiverEmail(defaultEmail); }, [defaultEmail]);
    var formRef = React.createRef();
    var handleSubmit = function (event) {
        event.preventDefault();
        formRef.current.reportValidity();
        if (!formRef.current.checkValidity() || !stations.length) {
            return false;
        }
        var from = splitTime(fromTime);
        var to = splitTime(toTime);
        var reportRequest = {
            stationIds: stations.map(function (_a) {
                var stationId = _a.stationId;
                return stationId;
            }),
            fromDate: moment(fromDate).hour(from.hour).minute(from.minute).toDate(),
            toDate: moment(toDate).hour(to.hour).minute(to.minute).toDate(),
            receiverEmail: receiverEmail,
            fileFormat: reportFileFormat,
        };
        createReport(reportRequest);
    };
    return (_jsxs("form", __assign({ ref: formRef, className: 'create-report-form form' }, { children: [_jsx(SelectStations, { loading: loading, onChange: setStations }), _jsxs("div", __assign({ className: 'report-input-container' }, { children: [_jsxs("div", __assign({ className: 'report-date-and-time' }, { children: [_jsx(InputDayPicker, { label: t('reports.create.from.date'), value: fromDate !== null ? moment(fromDate).format(DATE_FORMAT) : null, onChange: setFromDate, minDate: moment()
                                    .subtract(DATE_LIMIT_DAYS - 1, 'd')
                                    .toDate(), maxDate: new Date() }), _jsx(InputText, { name: 'fromTime', label: '\u00A0', placeholder: '00:00', value: fromTime, pattern: clockPattern, handleChange: function (e) { return setFromTime(e.target.value); } })] })), _jsxs("div", __assign({ className: 'report-date-and-time' }, { children: [_jsx(InputDayPicker, { label: t('reports.create.to.date'), value: toDate !== null ? moment(toDate).format(DATE_FORMAT) : null, onChange: setToDate, minDate: fromDate, maxDate: moment(fromDate)
                                    .add(DATE_RANGE_LIMIT_DAYS - 1, 'd')
                                    .toDate() }), _jsx(InputText, { name: 'toTime', label: '\u00A0', placeholder: '00:00', value: toTime, pattern: clockPattern, handleChange: function (e) { return setToTime(e.target.value); } })] }))] })), _jsxs("div", __assign({ className: 'report-input-container' }, { children: [_jsx(ReportFileFormat, {}), _jsx(InputText, { name: 'receiverEmail', label: t('reports.create.receiver.email'), value: receiverEmail, handleChange: function (e) { return setReceiverEmail(e.target.value); } })] })), _jsx(LoadingButton, { loading: loading, label: t('reports.create.submit'), disabled: !(stations.length &&
                    fromDate !== null &&
                    fromTime &&
                    toDate != null &&
                    toTime &&
                    receiverEmail), onClick: function (e) { return handleSubmit(e); } })] })));
};
var mapDispatchToProps = {
    createReport: createReport,
};
var mapStateToProps = function (state) { return ({
    loading: state.isLoading,
    defaultEmail: state.userInfo.data.email,
    reportFileFormat: state.reports.selectedFileFormat,
}); };
export default connect(mapStateToProps, mapDispatchToProps)(CreateReportForm);
