import { getRequest, postBlobRequest, postRequest } from './util';
var getOutgoing = function (trackingReference, stationId) {
    return getRequest("/scan/".concat(trackingReference, "?stationId=").concat(stationId));
};
var getReturn = function (trackingReference, stationId) {
    return getRequest("/returnscan/".concat(trackingReference, "?stationId=").concat(stationId));
};
var getBulkLabel = function (labels) { return postRequest('/label/bulk', labels); };
var ocrReturnScan = function (img, stationId) { return postBlobRequest('/ocr', img, stationId); };
export default {
    getOutgoing: getOutgoing,
    getBulkLabel: getBulkLabel,
    getReturn: getReturn,
    ocrReturnScan: ocrReturnScan,
};
