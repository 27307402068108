var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InputCheckbox from '../InputCheckbox';
import { changeSpeed, muteAliasSounds, toggleBluetoothDelay } from '../../redux/actions';
import t from '../../lib/translate';
import './index.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
var readingSpeedOptions = [1, 2, 3, 4];
var bluetoothOptions = function (bluetoothDelay) { return [
    { name: 'bluetoothDelay', value: bluetoothDelay, label: 'header.bluetooth' },
]; };
var ReturnScanOptions = function () {
    var dispatch = useAppDispatch();
    var mutedAliasSound = useAppSelector(function (state) { return state.user.mutedAliasSound; });
    var speed = useAppSelector(function (state) { return state.user.speed; });
    var bluetoothDelay = useAppSelector(function (state) { return state.user.bluetoothDelay; });
    return (_jsxs("div", __assign({ className: 'reading-options inside-tooltip' }, { children: [_jsx(InputCheckbox, { options: bluetoothOptions(bluetoothDelay), className: 'inside-tooltip', handleChange: function (e) {
                    e.stopPropagation();
                    dispatch(toggleBluetoothDelay());
                }, toggle: true }), _jsxs("div", __assign({ className: 'speed-wrapper' }, { children: [_jsx("label", { children: t('header.readingSpeed') }), _jsx("div", __assign({ className: 'speed-button-wrapper' }, { children: readingSpeedOptions.map(function (readingSpeedOption) { return (_jsx("button", __assign({ className: readingSpeedOption === speed ? 'active' : '', onClick: function (e) {
                                e.stopPropagation();
                                dispatch(changeSpeed(readingSpeedOption));
                            } }, { children: "".concat(readingSpeedOption, "x") }), readingSpeedOption)); }) }))] })), _jsxs("div", __assign({ className: 'mute-button-wrapper' }, { children: [_jsx("label", { children: t('header.mute.alias.sound') }), _jsx("button", __assign({ className: 'mute-button inside-tooltip', onClick: function (e) {
                            e.stopPropagation();
                            dispatch(muteAliasSounds());
                        }, type: 'button' }, { children: mutedAliasSound ? (_jsx("i", { className: 'fas fa-volume-mute' })) : (_jsx("i", { className: 'fas fa-volume-up' })) }))] }))] })));
};
export default ReturnScanOptions;
