import { SET_SCAN_MODE } from '../actions/scanMode';
var scanModeReducer = function (state, action) {
    if (state === void 0) { state = 'outgoing'; }
    switch (action.type) {
        case SET_SCAN_MODE:
            return action.data;
        default:
            return state;
    }
};
export default scanModeReducer;
