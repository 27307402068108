var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import sprintf from 'sprintf-js';
import no from '../i18n/no.json';
import en from '../i18n/en.json';
import sv from '../i18n/sv.json';
var translations = { no: no, en: en, sv: sv };
export var languages = [
    {
        value: 'no',
        label: 'Norsk',
    },
    {
        value: 'en',
        label: 'English',
    },
    {
        value: 'sv',
        label: 'Svenska',
    },
];
var fallback = 'no';
var language;
export var setLanguage = function (selectedLang) {
    language = selectedLang;
};
if (!translations[language]) {
    language = fallback;
}
var translation = function (key) { return translations[language][key] || translations[fallback][key] || key; };
var translate = function (key) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return args.length ? sprintf.sprintf.apply(sprintf, __spreadArray([translation(key)], args, false)) : translation(key);
};
export default translate;
