var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Loader from '../../components/Loader';
import AliasMessage from './AliasMessage';
import AliasError from './AliasError';
import t from '../../lib/translate';
import { setMode } from '../../redux/actions/scanMode';
import { connect } from 'react-redux';
import * as amplitude from '@amplitude/analytics-browser';
var AliasCard = function (_a) {
    var alias = _a.alias, currentTrackingReference = _a.currentTrackingReference, loading = _a.loading, descriptionI18nKey = _a.descriptionI18nKey, descriptionValue = _a.descriptionValue, setInfoScan = _a.setInfoScan, setMode = _a.setMode;
    var showAliasMessage = !loading && alias && currentTrackingReference && !alias.statusCode;
    var showAliasError = !loading && alias && currentTrackingReference && alias.statusCode;
    var showAliasOCRError = !loading && alias && alias.statusCode;
    var handleSwitchToInfoScan = function (e) {
        e.preventDefault();
        var mode = 'info';
        amplitude.track('Info Scan Accessed', {
            source: 'deep link',
            error: descriptionI18nKey && t(descriptionI18nKey, descriptionValue),
        });
        setMode(mode);
        setInfoScan(currentTrackingReference);
    };
    return (_jsxs("div", __assign({ className: "alias-card ".concat(showAliasError || showAliasOCRError ? 'error-background' : '') }, { children: [loading && _jsx(Loader, { classes: 'scanning-loader' }), _jsxs("div", __assign({ className: 'alias-card-info' }, { children: [showAliasMessage && _jsx(AliasMessage, { alias: alias }), 
                    /* eslint-disable no-mixed-operators */
                    (showAliasError || showAliasOCRError) && _jsx(AliasError, { error: alias }), descriptionI18nKey && (_jsx("div", __assign({ className: showAliasError ? 'parcel-description-error' : 'parcel-description-message' }, { children: t(descriptionI18nKey, descriptionValue) })))] })), currentTrackingReference && showAliasError && (_jsx("a", __assign({ href: '', onClick: handleSwitchToInfoScan, className: 'deep-link-scan-information' }, { children: t('scan.mode.info.deeplinktext') }))), currentTrackingReference && (_jsx("div", __assign({ className: showAliasError ? 'parcel-error' : 'parcel-message' }, { children: currentTrackingReference })))] })));
};
var mapDispatchToProps = {
    setMode: setMode,
};
export default connect(null, mapDispatchToProps)(AliasCard);
