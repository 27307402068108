var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import t from '../lib/translate';
var resetPasswordUrl = function () {
    var envLink = function () {
        switch (process.env.REACT_APP_ENV) {
            case 'dev':
                return 'dev-';
            case 'stg':
                return 'stg-';
            default:
                return '';
        }
    };
    return "https://".concat(envLink(), "app.di.no/auth/static/password/reset");
};
var PasswordExpiryWarning = function () { return (_jsxs("div", __assign({ className: 'warning-system-message' }, { children: [_jsx("i", { className: 'fas fa-exclamation-circle' }), t('system.information.password.expiry.warning'), _jsx("a", __assign({ className: 'link', href: resetPasswordUrl() }, { children: t('system.information.password.change.link') }))] }))); };
export default PasswordExpiryWarning;
