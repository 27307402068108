import { STATIONS_GET_SUCCEEDED } from '../actions';
var stationsReducer = function (state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case STATIONS_GET_SUCCEEDED:
            return action.data;
        default:
            return state;
    }
};
export default stationsReducer;
