var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { errorHandler } from './utils';
var Error = function (_a) {
    var error = _a.error, withMargin = _a.withMargin;
    return (_jsx("div", __assign({ className: "error".concat(withMargin ? ' margin' : '') }, { children: error.errorKey && _jsx("span", { children: errorHandler(error) }) })));
};
export default Error;
