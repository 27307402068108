import { parcelCounter } from '../../api';
export var GET_PARCEL_COUNTER_SUCCEEDED = 'GET_PARCEL_COUNTER_SUCCEEDED';
export var GET_PARCEL_COUNTER_FAILED = 'GET_PARCEL_COUNTER_FAILED';
export var PARCEL_COUNTER_INCREASE_OUTGOING = 'PARCEL_COUNTER_INCREASE_OUTGOING';
export var PARCEL_COUNTER_INCREASE_RETURN = 'PARCEL_COUNTER_INCREASE_RETURN';
export var getParcelCounter = function (stationId) { return function (dispatch) {
    return parcelCounter
        .get(stationId)
        .then(function (res) {
        return dispatch({
            type: GET_PARCEL_COUNTER_SUCCEEDED,
            data: res,
        });
    })
        .catch(function (err) {
        return dispatch({
            type: GET_PARCEL_COUNTER_FAILED,
            data: err,
        });
    });
}; };
export var increaseParcelCounterOutgoing = function () { return ({
    type: PARCEL_COUNTER_INCREASE_OUTGOING,
}); };
export var increaseParcelCounterReturn = function () { return ({
    type: PARCEL_COUNTER_INCREASE_RETURN,
}); };
