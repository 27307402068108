var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ALIAS_BRANCHES_GET_FAILED } from '../actions/aliasBranches';
import { AUTHENTICATION_FAILED } from '../actions/authentication';
import { GET_PARCEL_COUNTER_FAILED } from '../actions/parcelCounter';
import { RESET_ERRORS } from '../actions/resetErrors';
import { ROLE_GET_FAILED, STATIONS_GET_FAILED } from '../actions';
import { LOAD_CARRIERS_CREATE_FAILED } from '../actions/loadCarriers';
import { GET_BULK_LABEL_FAILED } from '../actions/bulkLabel';
var INITIAL_STATE = {
    authentication: false,
    stations: false,
    scan: false,
    aliasMapping: false,
    loadCarriers: false,
};
var parse = function (data) { return JSON.parse(data.message); };
var errorReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case RESET_ERRORS:
            return INITIAL_STATE;
        case AUTHENTICATION_FAILED:
            return __assign(__assign({}, state), { authentication: parse(action.data) });
        case STATIONS_GET_FAILED:
            console.error(action.data);
            return __assign(__assign({}, state), { stations: parse(action.data) });
        case ALIAS_BRANCHES_GET_FAILED:
            console.error(action.data);
            return __assign(__assign({}, state), { aliasMapping: parse(action.data) });
        case LOAD_CARRIERS_CREATE_FAILED:
            console.error(action.data);
            return __assign(__assign({}, state), { loadCarriers: parse(action.data) });
        case GET_PARCEL_COUNTER_FAILED:
        case ROLE_GET_FAILED:
        case GET_BULK_LABEL_FAILED:
            console.error(action.data);
            return __assign(__assign({}, state), { scan: parse(action.data) });
        default:
            return state;
    }
};
export default errorReducer;
