var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import t from '../lib/translate';
var LoadCarrierWrapper = function (_a) {
    var name = _a.name, disabled = _a.disabled, onClick = _a.onClick, children = _a.children, statusMessage = _a.statusMessage;
    return (_jsxs("div", __assign({ className: 'load-carriers-wrapper' }, { children: [_jsxs("div", __assign({ className: 'lc-wrapper-header-group' }, { children: [_jsx("div", __assign({ className: 'lc-wrapper-header' }, { children: t("".concat(name, ".header")) })), _jsxs("button", __assign({ onClick: onClick, disabled: disabled }, { children: [_jsx("i", { className: 'fas fa-print' }), t("".concat(name, ".print"))] }))] })), _jsx("div", __assign({ className: 'lc-message' }, { children: t("".concat(name, ".info")) })), children, _jsx("div", { children: statusMessage })] })));
};
export default LoadCarrierWrapper;
