import { authentication } from '../../api';
import { resetErrors } from './resetErrors';
import { setLoader } from './loader';
export var AUTHORIZED = 'AUTHORIZED';
export var UNAUTHORIZED = 'UNAUTHORIZED';
export var AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export var authenticated = {
    type: AUTHORIZED,
};
export var unauthorized = {
    type: UNAUTHORIZED,
};
export var logout = function () { return function (dispatch) {
    dispatch(unauthorized);
    localStorage.removeItem('jwt-token');
}; };
export var login = function (credentials) { return function (dispatch) {
    dispatch(setLoader(true));
    dispatch(resetErrors());
    authentication
        .login(credentials)
        .then(function () { return dispatch(authenticated); })
        .catch(function (err) {
        return dispatch({
            type: AUTHENTICATION_FAILED,
            data: err,
        });
    })
        .finally(function () {
        dispatch(setLoader(false));
    });
}; };
export var checkAuthentication = function () { return function (dispatch) {
    var validToken = !!localStorage.getItem('jwt-token');
    if (validToken) {
        dispatch(authenticated);
    }
    else {
        logout();
    }
}; };
