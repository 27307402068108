var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import t from '../../lib/translate';
import { simpleHash } from '../../lib/util';
import './index.scss';
var LaunchNotesButton = function () {
    var userName = useSelector(function (state) { var _a; return (_a = state.userInfo.data) === null || _a === void 0 ? void 0 : _a.username; });
    if (!userName) {
        return _jsx(_Fragment, {});
    }
    var token = 'public_kBUe8ogXUcYHcoCzvaFBIqqG';
    var project = 'pro_Fd3hCYI4mvyyk';
    var selector = 'launch-notes-button';
    var embed = "\n    <launchnotes-embed\n      token=\"".concat(token, "\"\n      project=\"").concat(project, "\"\n      categories='[\"cargostream\"]'\n      toggle-selector=\"#").concat(selector, "\"\n      heading=\"").concat(t('launch.notes.heading'), "\"\n      heading-color=\"#FFFFFF\"\n      subheading=\"").concat(t('launch.notes.subheading'), "\"\n      subheading-color=\"#FFFFFF\"\n      primary-color=\"#237AC2\"\n      widget-placement=\"auto\"\n      widget-offset-skidding=\"-150\"\n      widget-offset-distance=\"15\"\n      show-unread\n      anonymous-user-id=\"").concat(simpleHash(userName), "\"\n      unread-placement=\"right\"\n      unread-offset-skidding=\"0\"\n      unread-offset-distance=\"10\"\n      unread-background-color=\"#237AC2\"\n      unread-text-color=\"#ffffff\"\n    ></launchnotes-embed>\n  ");
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ id: selector }, { children: t('launch.notes.button.title') })), _jsx("div", { dangerouslySetInnerHTML: { __html: embed } })] }));
};
export default LaunchNotesButton;
