import { getRequest } from './util';
var setItemLocalStorage = function (key, item) {
    return new Promise(function (resolve) {
        localStorage.setItem(key, item);
        resolve(item);
    });
};
var get = function (lang, char) {
    var filename = "".concat(lang, "-").concat(char, ".mp3").toLowerCase();
    var file = localStorage.getItem(filename);
    if (file) {
        return new Promise(function (resolve) { return resolve(file); });
    }
    return getRequest("/sounds/".concat(lang, "/").concat(char)).then(function (base64Uri) {
        if (base64Uri.error) {
            throw base64Uri.error;
        }
        return setItemLocalStorage(filename, base64Uri);
    });
};
export default {
    get: get,
};
