var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getRequest } from '../api/util';
import t from '../lib/translate';
import '../style/views/InfoScan.scss';
import InfoScan from './InfoScan';
import Loader from '../components/Loader';
import * as amplitude from '@amplitude/analytics-browser';
var InfoScanForm = function (_a) {
    var stationId = _a.stationId, initialTrackingReference = _a.initialTrackingReference;
    var _b = React.useState(initialTrackingReference !== null && initialTrackingReference !== void 0 ? initialTrackingReference : ''), trackingReference = _b[0], setTrackingReference = _b[1];
    var language = localStorage.getItem('language');
    var _c = useQuery(['infoScan', trackingReference, stationId], function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getRequest("/scan/info/".concat(trackingReference, "?stationId=").concat(stationId, "&language=").concat(language))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); }, {
        staleTime: 120000,
        enabled: false,
        retry: 0,
        onSuccess: function (res) {
            amplitude.track('Info Scan API call', {
                status: 'fetched data successfully',
                'error messages': res.errorMessages,
            });
        },
        onError: function (e) {
            var errorKey;
            try {
                errorKey = JSON.parse(e.message).errorKey;
            }
            catch (parseError) {
                amplitude.track('Info Scan API call', {
                    status: 'failed to parse error',
                    'original error': e,
                    error: parseError,
                });
            }
            amplitude.track('Info Scan API call', {
                status: 'failed to fetch data',
                errorKey: errorKey,
            });
        },
    }), data = _c.data, error = _c.error, isLoading = _c.isLoading, isError = _c.isError, refetch = _c.refetch;
    useEffect(function () {
        if (trackingReference) {
            void refetch();
        }
    }, []);
    var handleSubmit = function (e) {
        e.preventDefault();
        amplitude.track("Info scan", { event: 'used search bar on page' });
        void refetch();
    };
    var errorKey = isError ? JSON.parse(error.message).errorKey : undefined;
    var renderContent = function () {
        if (isLoading) {
            return _jsx(Loader, { button: false, classes: 'scanning-loader' });
        }
        if (data) {
            return _jsx(InfoScan, { data: data });
        }
        if (isError) {
            return _jsx("div", __assign({ className: 'info-card error' }, { children: t(errorKey) }));
        }
        return _jsx("div", __assign({ className: 'info-card' }, { children: t('scan.mode.info.scantogetinfo') }));
    };
    return (_jsxs("div", { children: [_jsx("form", { children: _jsxs("div", __assign({ className: 'scan-form' }, { children: [_jsx("input", { type: 'text', name: 'trackingReference', placeholder: t('scan.tracking.reference.placeholder'), onChange: function (e) {
                                setTrackingReference(e.target.value);
                            }, value: trackingReference, autoFocus: true }), _jsx("button", __assign({ type: 'submit', onClick: handleSubmit, disabled: !trackingReference }, { children: t('button.label.search') }))] })) }), renderContent()] }));
};
export default InfoScanForm;
