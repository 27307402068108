var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Select from 'react-select';
import t from '../lib/translate';
import { useAppSelector } from '../redux/hooks';
import { useState } from 'react';
var SelectStations = function (_a) {
    var loading = _a.loading, onChange = _a.onChange;
    var stations = useAppSelector(function (state) { return state.stations; });
    var _b = useState([]), selectedStations = _b[0], setSelectedStations = _b[1];
    var handleChange = function (stations) {
        setSelectedStations(stations);
        onChange(stations);
    };
    var handleSelectAll = function () {
        handleChange(selectedStations.length ? [] : stations);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'react-select-select-all' }, { children: [_jsx("div", __assign({ className: 'label' }, { children: t('reports.create.stations') })), _jsx("span", __assign({ onClick: handleSelectAll }, { children: t(selectedStations.length ? 'load.carriers.label.empty' : 'load.carriers.label.select.all') }))] })), _jsx("div", __assign({ className: 'select-group' }, { children: _jsx(Select, { className: 'react-select-parent', classNamePrefix: 'react-select', value: selectedStations, options: stations, placeholder: '', isMulti: true, isDisabled: loading, getOptionLabel: function (_a) {
                        var name = _a.name;
                        return name;
                    }, getOptionValue: function (_a) {
                        var stationId = _a.stationId;
                        return "".concat(stationId);
                    }, onChange: handleChange }) }))] }));
};
export default SelectStations;
