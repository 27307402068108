var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var Loader = function (_a) {
    var button = _a.button, classes = _a.classes;
    return (_jsx("div", __assign({ className: "loader".concat(button ? ' button' : '', " ").concat(classes || '') }, { children: _jsx("i", { className: 'fas fa-spinner' }) })));
};
export default Loader;
