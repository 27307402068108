var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginPage from './views/LoginPage';
import AppRouter from './AppRouter';
import { checkAuthentication } from './redux/actions/authentication';
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App.prototype.componentDidMount = function () {
        this.props.checkAuthentication();
    };
    App.prototype.render = function () {
        var isAuthenticated = this.props.isAuthenticated;
        return _jsx(Router, { children: isAuthenticated ? _jsx(AppRouter, {}) : _jsx(LoginPage, {}) });
    };
    return App;
}(React.Component));
var mapDispatchToProps = {
    checkAuthentication: checkAuthentication,
};
var mapStateToProps = function (state) { return ({
    isAuthenticated: state.user.isAuthenticated,
}); };
export default connect(mapStateToProps, mapDispatchToProps)(App);
