var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { BULK_LABEL_ADDED, BULK_LABEL_PRINTED, GET_BULK_LABEL_FAILED } from '../actions/bulkLabel';
var INITIAL_STATE = {
    labels: [],
};
// eslint-disable-next-line default-param-last
var bulkLabelReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case BULK_LABEL_ADDED:
            return {
                countLimit: action.data.countLimit,
                timeLimit: action.data.timeLimit,
                timestamp: Date.now(),
                labels: __spreadArray(__spreadArray([], state.labels, true), [
                    {
                        parcelNumber: action.data.parcelNumber,
                        labelSystem: action.data.labelSystem,
                    },
                ], false),
            };
        case BULK_LABEL_PRINTED:
            return INITIAL_STATE;
        case GET_BULK_LABEL_FAILED:
            return INITIAL_STATE; // Reset state to allow scanning after errors
        default:
            return state;
    }
};
export default bulkLabelReducer;
