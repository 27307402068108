import * as api from '../api';
import { setLoader } from './actions/loader';
/* STATIONS */
export var STATIONS_GET_SUCCEEDED = 'STATIONS_GET_SUCCEEDED';
export var STATIONS_GET_FAILED = 'STATIONS_GET_FAILED';
export var RESET_ERROR_STATIONS = 'RESET_ERROR_STATIONS';
export var MUTE_ALIAS_SOUNDS = 'MUTE_ALIAS_SOUNDS';
export var CHANGE_SPEED = 'CHANGE_SPEED';
export var resetStationsError = function () { return ({
    type: RESET_ERROR_STATIONS,
}); };
export var muteAliasSounds = function () { return ({
    type: MUTE_ALIAS_SOUNDS,
}); };
export var changeSpeed = function (speed) { return ({
    type: CHANGE_SPEED,
    data: speed,
}); };
export var getStations = function () { return function (dispatch) {
    dispatch(setLoader(true));
    dispatch(resetStationsError());
    return api.stations
        .get()
        .then(function (res) {
        dispatch({
            type: STATIONS_GET_SUCCEEDED,
            data: res,
        });
    })
        .catch(function (err) {
        return dispatch({
            type: STATIONS_GET_FAILED,
            data: err,
        });
    })
        .finally(function () {
        dispatch(setLoader(false));
    });
}; };
/* ADMIN ACCESS */
export var ROLE_GET_SUCCEEDED = 'ROLE_GET_SUCCEEDED';
export var ROLE_GET_FAILED = 'ROLE_GET_FAILED';
export var hasRole = function () { return function (dispatch) {
    return api.hasRole
        .get()
        .then(function (res) {
        return dispatch({
            type: ROLE_GET_SUCCEEDED,
            data: res,
        });
    })
        .catch(function (err) {
        return dispatch({
            type: ROLE_GET_FAILED,
            data: err,
        });
    });
}; };
/* Handle Language  */
export var LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';
export var LANGUAGE_LOADED = 'LANGUAGE_LOADED';
export var changeLanguage = function (language) { return function (dispatch) {
    localStorage.setItem('language', language);
    return dispatch({
        type: LANGUAGE_CHANGED,
        data: language,
    });
}; };
export var loadLanguage = function () { return function (dispatch) {
    var language = localStorage.getItem('language');
    return dispatch({
        type: LANGUAGE_LOADED,
        data: language || 'no',
    });
}; };
export var TOGGLE_BLUETOOTH_DELAY = 'TOGGLE_BLUETOOTH_DELAY';
export var toggleBluetoothDelay = function () { return function (dispatch) {
    return dispatch({
        type: TOGGLE_BLUETOOTH_DELAY,
    });
}; };
