import * as api from '../../api';
import { setLoader } from './loader';
export var GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export var GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';
export var CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export var CREATE_REPORT_FAILURE = 'CREATE_REPORT_FAILURE';
export var RESET_REPORT_ERROR = 'RESET_REPORT_ERROR';
export var CHANGE_REPORT_FILE_FORMAT = 'CHANGE_REPORT_FILE_FORMAT';
export var getReports = function () { return function (dispatch) {
    dispatch(setLoader(true));
    dispatch({ type: RESET_REPORT_ERROR });
    return api.reports
        .getReports()
        .then(function (reports) {
        return dispatch({
            type: GET_REPORTS_SUCCESS,
            data: reports,
        });
    })
        .catch(function (err) {
        return dispatch({
            type: GET_REPORTS_FAILURE,
            data: JSON.parse(err.message),
        });
    })
        .finally(function () { return dispatch(setLoader(false)); });
}; };
export var createReport = function (reportCreateRequest) { return function (dispatch) {
    dispatch({ type: RESET_REPORT_ERROR });
    api.reports
        .createReport(reportCreateRequest)
        .then(function () {
        dispatch(getReports());
        dispatch({
            type: CREATE_REPORT_SUCCESS,
        });
    })
        .catch(function (err) {
        dispatch({
            type: CREATE_REPORT_FAILURE,
            data: JSON.parse(err.message),
        });
    });
}; };
export var setReportFileFormat = function (fileFormat) { return function (dispatch) {
    return dispatch({
        type: CHANGE_REPORT_FILE_FORMAT,
        data: fileFormat,
    });
}; };
